/* Import fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Capriola&family=Nunito:wght@300;400;700&display=swap');

/* CSS Reset */
*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
}

html {
  color-scheme: dark light;
}

body {
  min-height: 100vh;
  line-height: 1.5;
}

img, picture, svg, video {
  display: block;
  max-width: 100%;
}

/* Root variables */
:root {
  --primary-color: #5a248d;
  --secondary-color: #ffde91;
  --font-heading: 'Capriola', sans-serif;
  --font-body: 'Nunito', sans-serif;

  --screen-size-laptop: 1024px;
  --screen-size-tablet: 768px;
  --screen-size-mobile-xl: 576px;
  --screen-size-mobile-lg: 425px;
  --screen-size-mobile-sm: 320px;
}

/* Base styles */
body {
  font-family: var(--font-body);
  color: #333;
  background-color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
  color: var(--primary-color);
}

/* Additional utility classes */
.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.bg-primary {
  background-color: var(--primary-color);
  color: #fff;
}

.bg-secondary {
  background-color: var(--secondary-color);
  color: #333;
}